import { GoToWrapper } from './GoTo.wrapper';
import { FC, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';

export const GoToApp: FC = () => {
	const router = useRouter();
	const routerReady = useRef<boolean>(false);

	useEffect(() => {
		routerReady.current = router.isReady;
	}, [router]);

	const deeplink = 'outback://home';
	const appStoreAndroid = 'https://play.google.com/store/apps/details?id=com.outback.tampa';
	const appStoreIos = 'https://apps.apple.com/us/app/outback/id1035361376';

	const goToApp = () => {
		window.location.replace(deeplink);

		setTimeout(() => {
			const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
			const storeUrl = isIOS ? appStoreIos : appStoreAndroid;
			window.location.replace(storeUrl);
		}, 5000);
	};
	const redirectMethod = () => {
		const interval = setInterval(() => {
			if (routerReady.current) {
				goToApp();
				clearInterval(interval);
			}
		}, 100);
	};
	return <GoToWrapper redirectMethod={redirectMethod} />;
};
